<div class="flex flex-col gap-4">
	<form [formGroup]="service.appForm" class="grid grid-cols-2 gap-4">
		<yuno-forms-title class="col-span-full">Configuration</yuno-forms-title>

		<yuno-forms-text formControlName="_id" label="AppId" />
		<section formGroupName="client">
			<yuno-forms-text formControlName="_id" label="Client Id" />
		</section>
		<yuno-forms-text class="col-span-2" formControlName="id" label="Project Name / Id" />
		<yuno-forms-number
			class="col-span-2"
			formControlName="trackingId"
			label="Matomo Tracking Id" />
		<a target="_blank" rel="noopener" class="col-span-2 -mt-4" [href]="getMatomoUrl()">
			Open Matomo
		</a>
	</form>
	<form [formGroup]="service.configForm" class="grid grid-cols-1 gap-1">
		<yuno-forms-text formControlName="path" label="Content Path" />
		<yuno-admin-annotation type="warning">
			Only change this value at the start of the project. All connections to data stored on
			the servers will be lost!
		</yuno-admin-annotation>
	</form>
	<!--	<div class="flex flex-row justify-center">-->
	<!--		<button yuno-admin-button color="primary" (click)="openFileUpload()">Upload .YUNO</button>-->
	<!--	</div>-->
</div>
