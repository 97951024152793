<form [formGroup]="service.configForm" class="grid grid-cols-1 gap-12">
	<section formGroupName="interface" class="grid grid-cols-2 gap-4 md:grid-cols-3 xl:grid-cols-5">
		<yuno-forms-title class="col-span-full">General</yuno-forms-title>

		<yuno-forms-toggle formControlName="mapOnly" label="Map Only"></yuno-forms-toggle>
		<yuno-forms-toggle formControlName="controlZoom" label="Control Zoom"></yuno-forms-toggle>
	</section>

	<div class="flex flex-col gap-4">
		<section
			formGroupName="interface"
			class="grid grid-cols-2 gap-4 md:grid-cols-3 xl:grid-cols-5">
			<yuno-forms-title class="col-span-full">Full screen</yuno-forms-title>
			<yuno-forms-toggle
				formControlName="fullscreenButton"
				label="Fullscreen Button"></yuno-forms-toggle>
			<yuno-forms-toggle
				formControlName="fullscreenPopup"
				label="Fullscreen Popup"></yuno-forms-toggle>
			<yuno-forms-toggle
				formControlName="embedFullscreenPopup"
				label="Embed Fullscreen Popup"></yuno-forms-toggle>
		</section>
		<yuno-admin-annotation>
			<span>
				The
				<span class="font-semibold">"Fullscreen Popup"</span> is enabled when using inside a
				iFrame.
			</span>
			<span>
				The
				<span class="font-semibold">"Embed Fullscreen Popup"</span>
				is enabled when using the
				<span class="font-semibold italic">"&embed=true"</span> code in the application url.
			</span>
		</yuno-admin-annotation>
	</div>

	<!-- BUTTON STYLE -->
	<div class="flex flex-col gap-4">
		<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
			<yuno-forms-title class="col-span-full">Application Button Style</yuno-forms-title>
			<ng-container formGroupName="interface">
				<yuno-forms-select
					formControlName="buttonStyle"
					[display]="['Default', 'Rounded', 'Square']"
					[selectValues]="['default', 'rounded', 'square']"></yuno-forms-select>
			</ng-container>
			<div class="flex items-center justify-center gap-2">
				<yuno-button>Preview</yuno-button>
			</div>
		</div>
		<yuno-admin-annotation>
			<span>
				This will change the style of all components using a rounded finish, to either
				decrease or remove the border radius.
			</span>
			<span> This includes buttons, containers, dropdown items, hover states and more. </span>
		</yuno-admin-annotation>
	</div>
	<div class="flex flex-col gap-4">
		<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
			<yuno-forms-title class="col-span-full">Fonts</yuno-forms-title>

			<yuno-forms-select
				formControlName="fontHeader"
				label="Font Header"
				placeholder="default"
				[display]="service.fontDisplay"
				[selectValues]="service.fontSelect"></yuno-forms-select>
			<yuno-forms-select
				formControlName="fontParagraph"
				label="Font Paragraph"
				placeholder="default"
				[display]="service.fontDisplay"
				[selectValues]="service.fontSelect"></yuno-forms-select>
		</div>
		<yuno-admin-annotation type="info">
			This will replace the font in the application.
		</yuno-admin-annotation>
	</div>
</form>
