<form [formGroup]="service.appForm" class="mb-6 flex flex-col gap-6">
	<yuno-forms-toggle formControlName="public" label="Open for Public version" />
</form>
<yuno-forms-divider />

<form [formGroup]="service.configForm" class="mb-6 flex flex-col gap-6">
	<yuno-forms-text formControlName="title" label="Application title" />
	<div class="col-span-2 flex flex-col gap-1">
		<section formGroupName="customTitle">
			@for (lang of service.languageSelect; track lang) {
				@if (lang === this.language) {
					<yuno-forms-text [formControlName]="lang" label="Custom Browser Title" />
				}
			}
		</section>
		<yuno-admin-annotation type="info">
			This will replace the browser title: "Project Atlas |
			{{ service.title.value }}" in the application.
		</yuno-admin-annotation>
	</div>
</form>

<yuno-forms-divider />

<form [formGroup]="service.appForm" class="flex flex-col gap-6">
	<div class="flex flex-col gap-2">
		<yuno-forms-span class="font-semibold">Url *</yuno-forms-span>
		<yuno-forms-array-container [control]="service.urls" formArrayName="url">
			<div arrayBody class="grid grid-cols-1 gap-1 rounded-lg">
				@for (url of service.urls.controls; track url; let i = $index) {
					<yuno-admin-drag-row [hideDrag]="true" [keepButtons]="true">
						<div class="flex-1 select-none" title>
							<yuno-forms-text [formControlName]="i" placeholder="Application Url">
								@if (
									service.urls.controls[i].errors?.['minlength'] ||
									service.urls.controls[i].errors?.['required']
								) {
									<span> please provide at least 3 characters </span>
								}
								@if (
									service.urls.controls[i].errors?.['noSpacesLowerCase']?.[
										'whitespace'
									]
								) {
									<span> whitespaces are not allowed! </span>
								}
								@if (
									service.urls.controls[i].errors?.['noSpacesLowerCase']?.[
										'uppercase'
									]
								) {
									<span> only lowercase is allowed </span>
								}
							</yuno-forms-text>
						</div>
						<ng-container buttons>
							<button yuno-admin-delete-admin-button (click)="onRemove(i)"></button>
						</ng-container>
					</yuno-admin-drag-row>
				}
			</div>
			<div arrayErrors>
				@if (!service.urls.valid && !service.urls.errors) {
					<span> one of the urls is not valid! </span>
				}
				@if (service.urls.errors?.['minlength']) {
					<span> please provide at least one url! </span>
				}
			</div>
		</yuno-forms-array-container>
		<button yuno-admin-add-button class="self-end" (click)="onAdd()">Add Url</button>
	</div>
</form>
