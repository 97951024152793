import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'yuno-sidebar-item',
	templateUrl: './sidebar-item.component.html',
	styleUrls: ['./sidebar-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarItemComponent {
	@Input() link!: string[];
	@Input() display!: string;
	@Input() exact = false;
}
