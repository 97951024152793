import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewChild
} from '@angular/core';
import { kebabCase } from 'lodash';
import { Map } from 'maplibre-gl';

import { LanguageObjectModel, Legend, LegendOptions } from '@yuno/api/interface';

import { LegendItemComponent } from '../legend-item/legend-item.component';

@Component({
	selector: 'yuno-legend-container-contents',
	standalone: true,
	imports: [LegendItemComponent],
	template: `
		<ul class="w-62" #listContainer>
			@for (item of data.content; track trackByFn($index, item)) {
				<li>
					<yuno-legend-item
						[legend]="data"
						[language]="language"
						[data]="item"
						[map]="map" />
				</li>
			}
		</ul>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendContainerContentsComponent {
	@ViewChild('listContainer', { static: true }) listContainer: ElementRef;

	@Input() map: Map;

	_data: Partial<Legend>;
	@Input() set data(legend: Partial<Legend>) {
		// Set the data
		this._data = legend;
		setTimeout(() => {
			this.totalHeight.emit(this.getTotalHeight());
		}, 500);
	}

	get data(): Partial<Legend> {
		return this._data;
	}

	@Input() language: string | undefined = 'nl';

	@Output() totalHeight = new EventEmitter<number>();

	trackByFn(index: number, item: LegendOptions): string | number {
		if (!item.class) {
			return index;
		}

		let title: string;
		if (typeof item.title === 'string') {
			title = item.title;
		} else {
			title = (item.title as LanguageObjectModel)[this.language || 'nl'] || 'rand';
		}

		return kebabCase(`${item.class}-${item.color}-${title}`);
	}

	getTotalHeight(): number {
		return this.listContainer.nativeElement?.scrollHeight;
	}
}
