<a
	#rla="routerLinkActive"
	routerLinkActive
	[routerLink]="link"
	[routerLinkActive]="'active'"
	[routerLinkActiveOptions]="{ exact }"
	[ngClass]="{
		active: rla.isActive
	}"
	tabindex="0">
	<!--	class="mt-1 inline-flex w-full transform items-center rounded-full px-4 py-2 text-base transition duration-500 ease-in-out hover:bg-gray-900 hover:text-gray-200 focus:bg-yuno-blue-hover focus:text-gray-100">-->
	<ng-content select="[svg, .svgInline]"></ng-content>
	<span>{{ display }}</span>
</a>
