<form [formGroup]="service.configForm" class="grid grid-cols-1 gap-12">
	<!-- NAVBAR -->
	<div class="flex flex-col gap-4">
		<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
			<yuno-forms-title class="col-span-full">Navbar</yuno-forms-title>
			<ng-container formGroupName="interface">
				<yuno-forms-select
					formControlName="navbarType"
					label="Navbar Type"
					[display]="service.navbarTypeValues"
					[selectValues]="service.navbarTypeValues"></yuno-forms-select>
			</ng-container>
			<span></span>
			<ng-container formGroupName="colors">
				<ng-container formGroupName="navbar">
					<yuno-forms-toggle
						formControlName="underline"
						label="Active Underline"></yuno-forms-toggle>
				</ng-container>
			</ng-container>
		</div>
		<div formGroupName="colors">
			<div
				formGroupName="navbar"
				class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
				<yuno-forms-color formControlName="background" label="Background" />
				<yuno-forms-color formControlName="font" label="Font" />
				<yuno-forms-color formControlName="active" label="Active Background" />
				<yuno-forms-color formControlName="activeFont" label="Active Font" />
			</div>
		</div>
	</div>
	<!-- EMBED NAVBAR -->
	<div class="flex flex-col gap-4">
		<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
			<yuno-forms-title class="col-span-full">Embedded Navbar</yuno-forms-title>
			<ng-container formGroupName="interface">
				<yuno-forms-select
					formControlName="embedType"
					label="Navbar Type"
					[display]="service.navbarTypeValues"
					[selectValues]="service.navbarTypeValues"></yuno-forms-select>
			</ng-container>
			<span></span>
			<ng-container formGroupName="colors">
				<ng-container formGroupName="embedNavbar">
					<yuno-forms-toggle
						formControlName="underline"
						label="Active Underline"></yuno-forms-toggle>
				</ng-container>
			</ng-container>
		</div>
		<div formGroupName="colors">
			<div
				formGroupName="embedNavbar"
				class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
				<yuno-forms-color formControlName="background" label="Background" />
				<yuno-forms-color formControlName="font" label="Font" />
				<yuno-forms-color formControlName="active" label="Active Background" />
				<yuno-forms-color formControlName="activeFont" label="Active Font" />
			</div>
		</div>
	</div>
	<!-- OTHER COLORS -->
	<div formGroupName="colors" class="flex flex-col gap-12">
		<section>
			<yuno-forms-title>States</yuno-forms-title>
			<div
				formGroupName="states"
				class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
				<yuno-forms-color formControlName="background" label="Background" />
				<yuno-forms-color formControlName="font" label="Font" />
			</div>
		</section>
		<section>
			<yuno-forms-title>Buttons</yuno-forms-title>
			<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
				<yuno-forms-color formControlName="button" label="Background" />
				<yuno-forms-color formControlName="buttonFont" label="Font" />
			</div>
		</section>
		<section>
			<yuno-forms-title>Map Items</yuno-forms-title>
			<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
				<yuno-forms-color formControlName="panoramas" label="Panoramas" />
				<yuno-forms-color
					formControlName="panoramasClusterCount"
					label="Panoramas Cluster Count" />
				<yuno-forms-color formControlName="measure" label="Measure" />
			</div>
		</section>
		<section>
			<yuno-forms-title>Others</yuno-forms-title>
			<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
				<yuno-forms-color formControlName="additional" label="Compass" />
				<yuno-forms-color formControlName="textHeading" label="Text Heading" />
				<yuno-forms-color formControlName="textLink" label="Text Link" />
			</div>
		</section>
	</div>
</form>
