@if (data$ | async) {
	@if (data.title | languageSelect: language; as titleLanguage) {
		<span class="mb-4 text-xl font-semibold">{{ titleLanguage }}</span>
	} @else {
		<span class="mb-4 text-xl font-semibold">{{ title }}</span>
	}

	<yuno-legend-container-contents [language]="language" [data]="data" [map]="map" />
	
	<!-- Placeholder to correctly align with the external closing button -->
	<div class="absolute bottom-0 right-0 h-10 w-10"></div>
}
